import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const UsCurrencyDesignPage = () => {
    const article = {
        id: '60aec3de-138f-5518-865a-03d92b80cdaf',
        title: 'History of the US Dollar Design',
        slug: '/us-currency-design/',
        date: '2018-01-24T19:17:44.000Z',
        modified: '2021-09-08T19:14:36.000Z',
        excerpt: 'United States currency design has changed since Congress authorized notes in 1861. View before and after interactive sliders for $1, $5, $10, $20, and $100 bills.',
        featured_image: {
            source_url: '/media/history-of-the-dollar-sign.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 6,
        content: (
            <>
                <p>&quot;Time is money.&quot; For the most part, this common saying has held true. Over time, money itself has evolved. The United States currency has changed quite a lot since the first time Congress authorized notes for use in 1861.</p>
                <p>Since then, faces have changed, security has grown, and other changes abound. Use the sliders below to explore changes in sizes and dimensions on various currencies, both front and back.</p>
                <h2 id="1">$1</h2>
                <p>Issued in 1862, the first $1 bill featured then Secretary of the Treasury Salmon P. Chase; the bill first featuring George Washington appeared in 1869.</p>
                <p>In 1957, $1 silver certificates became the first U.S. currency to bear the motto &quot;IN GOD WE TRUST.&quot;</p>
                <h3>Front</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-01-f-1862.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-01-f-2009.jpg" />
                </div>
                <h3>Back</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-01-b-1862.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-01-b-2009.jpg" />
                </div>
                <p>
                    <em>
                        1862 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/United_States_one-dollar_bill#mediaviewer/File:US-$1-LT-1862-Fr-16c.jpg" rel="noreferrer">Front/Back</a>
                        . 2009 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:Onedolar2009series.jpg" rel="noreferrer">Front</a>
                        /
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US_one_dollar_bill,_reverse,_series_2009.jpg" rel="noreferrer">Back</a>
                        .
                    </em>
                </p>
                <h2 id="5">$5</h2>
                <p>Along with the $10 and $20 denominations, the $5 bill was actually issued in 1861—a year prior to the $1.</p>
                <p>The back features the Lincoln Memorial, complete with state names engraved on the front of the building.</p>
                <h3>Front</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-05-f-1923.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-05-f-2006.jpg" />
                </div>
                <h3>Back</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-05-b-1923.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-05-b-2006.jpg" />
                </div>
                <p>
                    <em>
                        1923 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US-$5-SC-1923-Fr-282-(A3347311B).jpg" rel="noreferrer">Front/Back</a>
                        . 2008 Source:
                        {' '}
                        <a target="_blank" href="http://upload.wikimedia.org/wikipedia/commons/c/c5/New_five_dollar_bill.jpg" rel="noreferrer">Front</a>
                        /
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:Series2006_NoteBack_5.jpg" rel="noreferrer">Back</a>
                        .
                    </em>
                </p>
                <h2 id="10">$10</h2>
                <p>To reduce manufacturing costs, all currency was reduced in size by around 30% in 1929, which is why the newer notes (including the $10 bill from 1933 shown here) appear smaller than the originals.</p>
                <p>The first $10 notes were issued in 1914 and featured a portrait of President Andrew Jackson on the face.</p>
                <h3>Front</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-10-f-1933.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-10-f-2004.jpg" />
                </div>
                <h3>Back</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-10-b-1933.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-10-b-2004.jpg" />
                </div>
                <p>
                    <em>
                        1933 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US-$10-SC-1933-Fr.1700.jpg" rel="noreferrer">Front/Back</a>
                        . 2004 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US10dollarbill-Series_2004A.jpg" rel="noreferrer">Front</a>
                        /
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US_$10_Series_2004_reverse.jpg" rel="noreferrer">Back</a>
                        .
                    </em>
                </p>
                <h2 id="20">$20</h2>
                <p>Originally issued in 1914, the first $20 bill featured President Cleveland; the portrait changed to Andrew Jackson in the 1928 series, circulating in 1929.</p>
                <p>Currency feature changes in 2003 included subtle background colors for the first time since the 1905 $20 Gold Certificate, starting with the new $20.</p>
                <h3>Front</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-20-f-1928.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-20-f-2006.jpg" />
                </div>
                <h3>Back</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-20-b-1928.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-20-b-2006.jpg" />
                </div>
                <p>
                    <em>
                        1928 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US-$20-FRN-1928-Fr-2050-G.jpg" rel="noreferrer">Front/Back</a>
                        . 2006 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US_$20_Series_2006_Obverse.jpg" rel="noreferrer">Front</a>
                        /
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US_$20_Series_2006_Reverse.jpg" rel="noreferrer">Back</a>
                        .
                    </em>
                </p>
                <h2 id="100">$100</h2>
                <p>The largest denomination note currently available is the $100 bill, but prior to 1969, currency was available in $500, $1,000, $5,000, and $10,000 denominations.</p>
                <p>The $100 note&rsquo;s 2013 redesign includes a number of security features, such as color-shifting ink and a 3-D security ribbon.</p>
                <h3>Front</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-100-f-1914.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-100-f-2009.jpg" />
                </div>
                <h3>Back</h3>
                <div>
                    <LazyLoadImage alt="picture of bill" src="/media/money-100-b-1914.jpg" />
                    <LazyLoadImage alt="picture of bill" src="/media/money-100-b-2009.jpg" />
                </div>
                <p>
                    <em>
                        1914 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:US-$100-FRN-1914-Fr-1074a.jpg" rel="noreferrer">Front/Back</a>
                        . 2009 Source:
                        {' '}
                        <a target="_blank" href="http://en.wikipedia.org/wiki/United_States_one_hundred-dollar_bill#mediaviewer/File:New100front.jpg" rel="noreferrer">Front</a>
                        /
                        <a target="_blank" href="http://en.wikipedia.org/wiki/File:New100back.jpg" rel="noreferrer">Back</a>
                        .
                    </em>
                </p>
                <p>
                    <em>
                        Further sources:
                        {' '}
                        <a href="https://www.uscurrency.gov/" target="_blank" rel="noreferrer">NewMoney.gov</a>
                        ,
                        {' '}
                        <a href="http://www.moneyfactory.gov/" target="_blank" rel="noreferrer">MoneyFactory.gov</a>
                        ,
                        {' '}
                        <a href="http://onedollarbill.org/" target="_blank" rel="noreferrer">OneDollarBill.org</a>
                    </em>
                </p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default UsCurrencyDesignPage;
